// src/pages/Download.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import NavbarLoginYes from '../components/navbars/navbar_login_yes';
import NavbarNotLoginYet from '../components/navbars/navbar_notlogin_yet';
import Footer from '../components/footer/footer';
import axios from 'axios';

const Download = () => {
    const location = useLocation();
    const filePath = location.state?.filePath; // Récupère le chemin du fichier depuis le state de la navigation
    const { isAuthenticated } = useAuth();

    const handleDownload = async () => {
        if (!filePath) {
            alert("Chemin du fichier non disponible pour le téléchargement.");
            return;
        }

        try {
            const response = await axios.get(`https://cvflash.fr/api/api/download-cv?file_path=${encodeURIComponent(filePath)}`, {
                responseType: 'blob', // Important pour le téléchargement de fichiers
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'cv_generated.pdf'); // Nom du fichier téléchargé
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Erreur lors du téléchargement du CV:", error);
            alert("Erreur lors du téléchargement du CV. Veuillez réessayer.");
        }
    };

    return (
        <div className="h-screen overflow-y-auto bg-gradient-to-r from-blue-800 to-indigo-900">
            {isAuthenticated ? <NavbarLoginYes /> : <NavbarNotLoginYet />}

            <div className="flex flex-col items-center justify-center mt-20 mb-20 min-h-[calc(100vh-20vh)] px-[8%]">
                <div className="bg-white p-10 rounded-3xl shadow-2xl max-w-xl w-full text-center">
                    <h2 className="text-3xl font-extrabold text-blue-900 mb-6">CV généré avec succès !</h2>
                    <p className="text-gray-600 text-lg mb-8">
                        Votre CV a été généré. Cliquez sur le bouton ci-dessous pour le télécharger.
                    </p>
                    <button
                        onClick={handleDownload}
                        className="py-3 px-6 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out"
                    >
                        Télécharger le CV
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Download;
