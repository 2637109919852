// src/components/NavbarLoginYes.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import profilImage from '../img/user.png';

const NavbarLoginYes = () => {
    const navigate = useNavigate();
    const { userEmail, logout } = useAuth();
    const [showDropdown, setShowDropdown] = useState(false);

    const handleLogout = () => {
        logout();
        navigate('/'); // Redirection vers la page d'accueil après déconnexion
    };

    return (
        <div className="flex items-center sticky top-0 z-50 w-full pt-6 pb-6 px-[8%] bg-gradient-to-r from-blue-800 to-indigo-900 bg-opacity-90 text-white shadow-md backdrop-blur-md">
            <div className="mr-auto pr-4">
                <a href="/" className="text-white text-4xl font-extrabold transition-transform duration-300 ease-in-out hover:scale-110">
                    CV<span className="text-3xl text-indigo-300">flash.fr</span>
                </a>
            </div>

            <nav className="flex-grow text-white text-xl font-bold flex justify-center space-x-14">
                <a href="/" className="hover:underline">Accueil</a>
                <a onClick={() => navigate('/generate-cv')} className="hover:underline">CV</a>
                <a onClick={() => navigate('/pricing')} className="hover:underline">Prix</a>
                <a onClick={() => navigate('/Contact')} className="hover:underline">Contact</a>
            </nav>

            <div className="relative ml-4">
                <img
                    src={profilImage}
                    alt="Profil"
                    className="w-10 h-10 rounded-full cursor-pointer"
                    onClick={() => setShowDropdown(!showDropdown)}
                />

                {showDropdown && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-50">
                        <p className="px-4 py-2 text-gray-800">Bonjour, {userEmail}</p>
                        <hr className="my-1" />
                        <button
                            onClick={handleLogout}
                            className="w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                            Déconnexion
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NavbarLoginYes;
