// src/components/pulsating-button.js
"use client";

import React from "react";

const PulsatingButton = ({
  className,
  children,
  pulseColor = "#0096ff",
  duration = "1.5s",
  ...props
}) => {
  return (
    <button
      className={`relative text-center cursor-pointer flex justify-center items-center rounded-lg text-white bg-blue-500 px-4 py-2 ${className}`}
      style={{
        "--pulse-color": pulseColor,
        "--duration": duration,
      }}
      {...props}
    >
      <div className="relative z-10">{children}</div>
      <div className="absolute top-1/2 left-1/2 size-full rounded-lg bg-inherit animate-pulse -translate-x-1/2 -translate-y-1/2" />
    </button>
  );
};

export default PulsatingButton;
