import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import githubIcon from '../components/img/github.png';
import linkedinIcon from '../components/img/linkedin.png';
import NavbarNotLoginYet from '../components/navbars/navbar_notlogin_yet';
import Footer from '../components/footer/footer';

const RegisterPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '', confirmPassword: '' });
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const validatePassword = (pwd) => {
        const minLength = 8;
        const hasUppercase = /[A-Z]/.test(pwd);
        const hasNumber = /[0-9]/.test(pwd);

        if (pwd.length < minLength) {
            return 'Le mot de passe doit contenir au moins 8 caractères.';
        }
        if (!hasUppercase) {
            return 'Le mot de passe doit contenir au moins une majuscule.';
        }
        if (!hasNumber) {
            return 'Le mot de passe doit contenir au moins un chiffre.';
        }
        return '';
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const passwordError = validatePassword(password);
        const confirmPasswordError = password !== confirmPassword ? 'Les mots de passe ne correspondent pas.' : '';

        if (passwordError || confirmPasswordError) {
            setErrors({ ...errors, password: passwordError, confirmPassword: confirmPasswordError });
            return;
        }

        setErrors({ email: '', password: '', confirmPassword: '' });

        try {
            await axios.post('https://cvflash.fr/api/api/register', { email, password });
            setIsPopupVisible(true); // Afficher la popup après une inscription réussie
        } catch (error) {
            if (error.response && error.response.status === 409) {
                // Email déjà utilisé
                setErrors({ ...errors, email: 'Cet email est déjà utilisé. Veuillez en choisir un autre.' });
            } else {
                console.error("Erreur lors de l'inscription:", error.response ? error.response.data : error.message);
                alert("Erreur lors de l'inscription. Veuillez réessayer.");
            }
        }
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
        navigate('/login'); // Redirige vers la page de connexion après fermeture de la popup
    };

    return (
        <div className="h-screen overflow-y-auto bg-gradient-to-r from-blue-800 to-indigo-900 relative">
            <NavbarNotLoginYet />

            {/* Popup Modal */}
            {isPopupVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-80 max-w-sm text-center">
                        <h3 className="text-2xl font-semibold text-blue-800 mb-4">Inscription réussie !</h3>
                        <p className="text-gray-700 mb-6">Vous êtes maintenant inscrit.</p>
                        <button
                            onClick={handleClosePopup}
                            className="px-4 py-2 bg-indigo-700 text-white rounded-lg shadow-md hover:bg-indigo-800 transition duration-300"
                        >
                            Aller à la connexion
                        </button>
                    </div>
                </div>
            )}

            {/* Register Form Section */}
            <div className="flex justify-center items-center mt-20 mb-20 min-h-[calc(100vh-20vh)] px-[8%]">
                <div className="bg-white p-10 rounded-3xl shadow-2xl max-w-md w-full">
                    <h2 className="text-3xl font-extrabold text-blue-900 mb-6 text-center">Inscription</h2>
                    <form className="space-y-6" onSubmit={handleRegister}>
                        <div>
                            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                            <input
                                className={`w-full py-3 px-4 rounded-lg shadow-inner border ${
                                    errors.email ? 'border-red-500' : 'border-gray-300'
                                } focus:outline-none focus:border-indigo-500 transition duration-300`}
                                id="email"
                                type="email"
                                placeholder="Votre email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && (
                                <p className="text-red-500 text-sm mt-2">{errors.email}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Mot de passe</label>
                            <input
                                className={`w-full py-3 px-4 rounded-lg shadow-inner border ${
                                    errors.password ? 'border-red-500' : 'border-gray-300'
                                } focus:outline-none focus:border-indigo-500 transition duration-300`}
                                id="password"
                                type="password"
                                placeholder="Votre mot de passe"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && (
                                <p className="text-red-500 text-sm mt-2">{errors.password}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="confirm-password" className="block text-gray-700 text-sm font-bold mb-2">Confirmez le mot de passe</label>
                            <input
                                className={`w-full py-3 px-4 rounded-lg shadow-inner border ${
                                    errors.confirmPassword ? 'border-red-500' : 'border-gray-300'
                                } focus:outline-none focus:border-indigo-500 transition duration-300`}
                                id="confirm-password"
                                type="password"
                                placeholder="Confirmez votre mot de passe"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {errors.confirmPassword && (
                                <p className="text-red-500 text-sm mt-2">{errors.confirmPassword}</p>
                            )}
                        </div>
                        <button
                            type="submit"
                            className="w-full py-3 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out"
                        >
                            S'inscrire
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RegisterPage;

