// navbar_notlogin_yet.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavbarNotLoginYet = () => {
    const navigate = useNavigate();

    return (
        <div className="flex items-center sticky top-0 z-50 w-full pt-6 pb-6 px-[8%] bg-gradient-to-r from-blue-800 to-indigo-900 bg-opacity-90 text-white shadow-md backdrop-blur-md">
            {/* Logo Section */}
            <div className="mr-auto pr-4">
                <a href="/" className="text-white text-4xl font-extrabold transition-transform duration-300 ease-in-out hover:scale-110">
                    CV<span className="text-3xl text-indigo-300">flash.fr</span>
                </a>
            </div>

            {/* Navigation Links */}
            <nav className="flex-grow text-white text-xl font-bold flex justify-center space-x-14">
                <a href="/" className="hover:underline">Accueil</a>
                <a href="" className="hover:underline" onClick={() => navigate('/generate-cv')}>CV</a>
                <a href="" className="hover:underline" onClick={() => navigate('/pricing')}>Prix</a>
                <a href="" className="hover:underline" onClick={() => navigate('/Contact')}>Contact</a>
            </nav>

            {/* Connexion and Inscription Buttons */}
            <div className="ml-4 flex space-x-4">
                <button
                    onClick={() => navigate('/register')}
                    className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300 ease-in-out"
                >
                    Inscription
                </button>
                <button
                    onClick={() => navigate('/login')}
                    className="px-6 py-2 bg-white text-blue-800 font-bold rounded-lg shadow-md hover:bg-gray-200 transition duration-300 ease-in-out"
                >
                    Connexion
                </button>
            </div>
        </div>
    );
};

export default NavbarNotLoginYet;