// src/components/magicui/typing-animation.js
import React, { useState, useEffect } from 'react';

const TypingAnimation = ({ text, className }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex(index + 1);
      }, 100); // Durée entre chaque lettre (100 ms)
      return () => clearTimeout(timeout);
    }
  }, [index, text]);

  return <h1 className={className}>{displayedText}</h1>;
};

export default TypingAnimation;
