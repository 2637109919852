// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Lire l'état d'authentification du localStorage au chargement initial
        return localStorage.getItem('isAuthenticated') === 'true';
    });
    const [userEmail, setUserEmail] = useState(() => {
        return localStorage.getItem('userEmail') || '';
    });

    // Fonction pour se connecter
    const login = (email) => {
        setIsAuthenticated(true);
        setUserEmail(email);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userEmail', email);
    };

    // Fonction pour se déconnecter
    const logout = () => {
        setIsAuthenticated(false);
        setUserEmail('');
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userEmail');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userEmail, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
