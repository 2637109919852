// footer.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer className="bg-gradient-to-r from-blue-900 to-indigo-950 p-8 rounded-t-lg shadow-lg">
            <div className="max-w-screen-xl mx-auto text-center">
                {/* Site Name */}
                <div className="mb-6">
                    <span className="text-2xl font-extrabold text-white">CV<span className="text-indigo-300">flash.fr</span></span>
                </div>

                {/* Navigation Links */}
                <ul className="flex flex-wrap justify-center space-x-10 text-sm font-medium text-gray-300 mb-8">
                    <li>
                        <a href="/" className="hover:text-white transition-colors duration-200">Accueil</a>
                    </li>
                    <li>
                        <a href="" className="hover:text-white transition-colors duration-200" onClick={() => navigate('/generate-cv')}>CV</a>
                    </li>
                    <li>
                        <a href="" className="hover:text-white transition-colors duration-200" onClick={() => navigate('/pricing')}>Prix</a>
                    </li>
                    <li>
                        <a href="" className="hover:text-white transition-colors duration-200" onClick={() => navigate('/Contact')}>Contact</a>
                    </li>
                    <li>
                        <a href="" className="hover:text-white transition-colors duration-200" onClick={() => navigate('/cgu')}>Conditions générales</a>
                    </li>
                </ul>

                {/* Separator */}
                <hr className="my-4 border-gray-600 mx-auto max-w-3xl" />

                {/* Copyright */}
                <span className="block text-sm text-gray-400 mt-6">
                    © 2024 <span className="font-semibold text-indigo-300">CVflash.fr™</span>. Tous droits réservés.
                </span>
            </div>
        </footer>
    );
};

export default Footer;
