// src/pages/cgu.js
import React from 'react';
import NavbarNotLoginYet from '../components/navbars/navbar_notlogin_yet';
import Footer from '../components/footer/footer';

const CGUPage = () => {
    return (
        <div className="h-screen overflow-y-auto bg-gradient-to-r from-blue-800 to-indigo-900 relative">
            <NavbarNotLoginYet />
            <div className="container mx-auto px-4 py-10 bg-white rounded-3xl shadow-2xl max-w-4xl mt-20 mb-20 p-8">
                <h1 className="text-3xl font-bold text-blue-900 mb-6 text-center">Conditions Générales d'Utilisation</h1>

                <h2 className="text-2xl font-semibold text-blue-700 mt-4 mb-2">1. Préambule</h2>
                <p className="text-gray-700 mb-4">
                    Les présentes conditions générales d'utilisation (CGU) ont pour objet de définir les modalités et conditions
                    d'utilisation du site et des services proposés. En utilisant ce site, vous acceptez sans réserve les présentes CGU.
                </p>

                <h2 className="text-2xl font-semibold text-blue-700 mt-4 mb-2">2. Création de compte</h2>
                <p className="text-gray-700 mb-4">
                    Pour accéder aux services de notre site, vous devez créer un compte utilisateur en fournissant une adresse e-mail et un mot de passe.
                    Vous vous engagez à fournir des informations exactes et à jour, et à ne pas divulguer vos identifiants de connexion.
                </p>

                <h2 className="text-2xl font-semibold text-blue-700 mt-4 mb-2">3. Collecte des données personnelles</h2>
                <p className="text-gray-700 mb-4">
                    Notre site collecte uniquement les données personnelles nécessaires à la création d'un compte utilisateur : votre adresse e-mail
                    et votre mot de passe. Nous ne collectons pas d'autres informations, y compris celles contenues dans le CV.
                </p>
                <p className="text-gray-700 mb-4">
                    Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez des droits suivants concernant vos
                    données personnelles :
                </p>
                <ul className="list-disc list-inside text-gray-700 mb-4">
                    <li>Accès : Vous pouvez demander l'accès aux informations que nous détenons à votre sujet.</li>
                    <li>Rectification : Vous pouvez demander la modification des informations incorrectes ou incomplètes.</li>
                    <li>Suppression : Vous pouvez demander la suppression de votre compte et des informations associées.</li>
                    <li>Limitation : Vous pouvez demander la limitation du traitement de vos données dans certaines conditions.</li>
                </ul>
                <p className="text-gray-700 mb-4">
                    Pour exercer vos droits ou pour toute question sur le traitement de vos données, vous pouvez nous contacter à l'adresse e-mail suivante : cv.flashcontact@gmail.com.
                </p>

                <h2 className="text-2xl font-semibold text-blue-700 mt-4 mb-2">4. Sécurité des données</h2>
                <p className="text-gray-700 mb-4">
                    Nous mettons en œuvre les mesures nécessaires pour garantir la sécurité de vos informations personnelles, notamment le chiffrement
                    de votre mot de passe. Cependant, nous ne pouvons pas garantir une sécurité absolue contre les tentatives d'accès non autorisées.
                </p>

                <h2 className="text-2xl font-semibold text-blue-700 mt-4 mb-2">5. Limitation de responsabilité</h2>
                <p className="text-gray-700 mb-4">
                    Nous nous efforçons de maintenir le site accessible et fonctionnel, mais ne pouvons être tenus responsables en cas d'interruptions,
                    de bugs, ou de toute autre défaillance technique. Nous ne garantissons pas que le site sera toujours disponible ou exempt d'erreurs.
                </p>

                <h2 className="text-2xl font-semibold text-blue-700 mt-4 mb-2">6. Modification des CGU</h2>
                <p className="text-gray-700 mb-4">
                    Nous nous réservons le droit de modifier les présentes conditions générales à tout moment. Les modifications entreront en vigueur dès
                    leur publication sur le site. Nous vous invitons à consulter régulièrement les CGU pour prendre connaissance des éventuelles modifications.
                </p>

                <h2 className="text-2xl font-semibold text-blue-700 mt-4 mb-2">7. Droit applicable</h2>
                <p className="text-gray-700 mb-4">
                    Les présentes CGU sont soumises au droit en vigueur dans votre pays de résidence. En cas de litige, les tribunaux compétents seront ceux
                    du lieu de résidence de l'utilisateur.
                </p>

                <p className="text-gray-700 mt-8">
                    En utilisant notre site, vous reconnaissez avoir pris connaissance des présentes CGU et les accepter pleinement.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default CGUPage;
